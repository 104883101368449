import React from "react";
import PortfolioContainer from "./components/PortfolioContainer";

export default function App() {
  return (
    <div>
       <PortfolioContainer /> 
    </div>
  );
}


